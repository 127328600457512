import { usePageContent } from "@appiodev/xcore-client/xcore-ui";
import { Box, Button, Container, Stack, Typography } from "@xcorejs/ui";
import Layout from "components/Layout";
import Subheader from "components/Layout/Subheader";
import RobeHeading from "design-system/robe/RobeHeading";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { getRobeProps, RobePage } from "xcore";
import { Error404 } from "xcore/types";
import { LoadingPage } from "components/withAuth";

export const getStaticProps = getRobeProps(({ cms }) => ({
  pageContent: cms.content.single("error404")
}));
const Error: RobePage = () => {
  const [{ values }, { stringify }] = usePageContent<Error404>();

  const router = useRouter();

  const is404afterDomainSwitch = router.asPath.includes("mode=domainSwitch");

  // If content does not exist after domain switch, user is redirected onto unavailable locale page
  useEffect(() => {
    is404afterDomainSwitch && router.push("/unavailable");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (is404afterDomainSwitch) return <LoadingPage />;

  return (
    <Container flexDirection="column" paddingTop={{ _: "3rem", sm: "5rem" }} paddingBottom={{ _: "6rem", sm: "10rem" }}>
      <Box width="85rem" maxWidth="100%" margin="auto">
        <RobeHeading mb="1rem">
          {stringify(values.title)}
        </RobeHeading>
        <Stack direction="column" gap={{ _: "2rem", sm: "3rem" }} align="center">
          <Typography variant="p">
            {stringify(values.subtitle)}
          </Typography>
          <Typography variant="lead" textAlign="center">
            {stringify(values.message)}
          </Typography>
          <Box mt="3rem">
            <Button as="a" href="/">
              {stringify(values.buttonText)}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

Error.Layout = Layout;
Error.Subheader = Subheader;

export default Error;
